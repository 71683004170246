import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-group mb-4"
}
const _hoisted_2 = { class: "form-group mb-4" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "form-group mb-4" }
const _hoisted_5 = { class: "d-flex justify-content-between align-items-center mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputWrapper = _resolveComponent("InputWrapper")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("form", {
    action: "#",
    class: "mt-4",
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleLogin && _ctx.handleLogin(...args)), ["prevent"]))
  }, [
    (_ctx.requireOtp)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[5] || (_cache[5] = _createElementVNode("label", { for: "otp" }, "One time OTP", -1)),
          _createVNode(_component_InputWrapper, {
            errors: _ctx.formError.otp
          }, {
            default: _withCtx(() => [
              _cache[4] || (_cache[4] = _createElementVNode("span", { class: "input-group-text" }, [
                _createElementVNode("span", { class: "fas fa-user-shield" })
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.otp) = $event)),
                type: "number",
                class: "form-control px-1",
                placeholder: "******",
                id: "otp",
                required: ""
              }, null, 512), [
                [_vModelText, _ctx.formData.otp]
              ])
            ]),
            _: 1
          }, 8, ["errors"])
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_2, [
            _cache[7] || (_cache[7] = _createElementVNode("label", { for: "email" }, "Your Username", -1)),
            _createVNode(_component_InputWrapper, {
              errors: _ctx.formError.email
            }, {
              default: _withCtx(() => [
                _cache[6] || (_cache[6] = _createElementVNode("span", {
                  class: "input-group-text",
                  id: "basic-addon1"
                }, [
                  _createElementVNode("span", { class: "fas fa-envelope" })
                ], -1)),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.email) = $event)),
                  type: "text",
                  class: "form-control px-1",
                  placeholder: "email@email.com",
                  id: "email",
                  autofocus: "",
                  required: ""
                }, null, 512), [
                  [_vModelText, _ctx.formData.email]
                ])
              ]),
              _: 1
            }, 8, ["errors"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[9] || (_cache[9] = _createElementVNode("label", { for: "password" }, "Your Password", -1)),
              _createVNode(_component_InputWrapper, {
                errors: _ctx.formError.password
              }, {
                default: _withCtx(() => [
                  _cache[8] || (_cache[8] = _createElementVNode("span", {
                    class: "input-group-text",
                    id: "basic-addon2"
                  }, [
                    _createElementVNode("span", { class: "fas fa-unlock-alt" })
                  ], -1)),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.password) = $event)),
                    type: "password",
                    placeholder: "Password",
                    class: "form-control px-1",
                    id: "password",
                    required: ""
                  }, null, 512), [
                    [_vModelText, _ctx.formData.password]
                  ])
                ]),
                _: 1
              }, 8, ["errors"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", null, [
                _createVNode(_component_router_link, {
                  to: "forgot_p",
                  class: "small text-right"
                }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode("Lost password?")
                  ])),
                  _: 1
                })
              ])
            ])
          ])
        ], 64)),
    _cache[11] || (_cache[11] = _createElementVNode("button", {
      type: "submit",
      class: "btn w-100 btn-dark"
    }, "Sign in", -1))
  ], 32))
}