import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/logo.png'


const _hoisted_1 = { class: "signin-inner my-3 my-lg-0 shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500" }
const _hoisted_2 = { class: "text-center text-md-center mb-4 mt-md-0" }
const _hoisted_3 = { class: "mb-0 h3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LoginForm = _resolveComponent("LoginForm")!
  const _component_DefaultLoader = _resolveComponent("DefaultLoader")!

  return (_openBlock(), _createBlock(_component_DefaultLoader, { loading: $setup.isNowLoading }, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, [
            _createVNode(_component_router_link, { to: "/" }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  height: "34",
                  alt: "Paidmoolah Logo"
                }, null, -1)
              ])),
              _: 1
            }),
            _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
            _cache[2] || (_cache[2] = _createTextVNode(" Control Panel "))
          ])
        ]),
        _createVNode(_component_LoginForm)
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}